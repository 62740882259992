import React, { useEffect, useRef } from 'react';
import { arrayOf, shape, func, number, bool, string } from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import scrollIntoViewSmoothly from 'utils/smoothScrollIntoView';

import { DELAY_500 } from 'shared/constants/delays';
import {
  chatHistorySelector,
  chatLoadingSelector,
  chatLoadingStateDelaySelector,
} from 'selectors/booking/chat-selectors';
import LoadingState from 'components/common/LoadingState';
import LoadingStateText from 'components/common/LoadingStateText';

import styles from './styles.module.scss';

const ChatPanel = ({
  chatHistory,
  isLoading,
  loadingStateDelay,
  env,
  avatar,
}) => {
  const messagesEndRef = useRef(null);

  useEffect(() => {
    if (env === 'widget') {
      scrollIntoViewSmoothly(messagesEndRef.current, { behavior: 'smooth', block: 'end' });
      const widgetElement = document.getElementById('widget');

      if (widgetElement) {
        window.scroll(0, widgetElement.scrollHeight);
      }
    }
  }, []);

  useEffect(() => {
    if (messagesEndRef.current && messagesEndRef.current.ownerDocument) {
      scrollIntoViewSmoothly(messagesEndRef.current, { behavior: 'smooth', block: 'end' });
    }
  }, [chatHistory]);

  return (
    <div className={cx({
      [styles.containerWidget]: env === 'widget',
      [styles.containerMobile]: env === 'mobile' && !avatar,
      [styles.containerAvatar]: avatar,
    })}
    >
      <div className={cx({
        [styles.scrollableWidget]: env === 'widget',
        [styles.scrollableMobile]: env === 'mobile',
      })}
      >
        {chatHistory.map(({ component, props, id }) => {
          const ChatComponent = component;
          return <ChatComponent key={id} {...props} />;
        })}
        {
          (() => {
            if (isLoading && chatHistory) {
              if (chatHistory[chatHistory.length - 1]?.id === 1) {
                return (
                  <LoadingStateText
                    delay={loadingStateDelay}
                    text="I'm retrieving your information, this may take a few seconds..."
                  />
                );
              }
              return <LoadingState delay={loadingStateDelay} />;
            }
            return null;
          })()
        }
        <div className={styles.lastMessage} ref={messagesEndRef} />
      </div>
    </div>
  );
};

ChatPanel.propTypes = {
  chatHistory: arrayOf(shape({
    component: func.isRequired,
    props: shape({}).isRequired,
    id: number.isRequired,
  })).isRequired,
  isLoading: bool,
  avatar: bool,
  loadingStateDelay: number,
  env: string,
};

ChatPanel.defaultProps = {
  isLoading: false,
  avatar: false,
  loadingStateDelay: DELAY_500,
  env: 'widget',
};

const mapStateToProps = state => ({
  chatHistory: chatHistorySelector(state),
  isLoading: chatLoadingSelector(state),
  loadingStateDelay: chatLoadingStateDelaySelector(state),
});

export default connect(mapStateToProps, null)(ChatPanel);
