import { isDevelopmentEnv } from 'shared/utils';
import {
  addPickupJob,
  addTechnicianJob,
} from 'shared/api';
import {
  DEALERSHIP_CLOSED_ERROR_CODE,
  DT_CUSTOMER_NOT_FOUND_ERROR_CODE,
  CDK_EXTRACT_CUSTOMER_NOT_FOUND_ERROR_CODE,
  APPOINTMENT_SLOT_FULL_ERROR_MESSAGE,
} from 'shared/constants/error-codes';
import { AVAILABLE_RECALLS_STEP, MILEAGE_STEP } from 'shared/constants/booking-steps';
import { DELAY_1500 } from 'shared/constants/delays';
import {
  chatPreferredPhoneNumberSelector,
  chatCurrentCustomerSelector,
  chatIdentificationMethodSelector,
  chatPreferredAddressSelector,
  chatSelectedSkipStepsSelector,
  chatSelectedVehicleIdFromParamsSelector,
  chatSelectedSkipStepsToMilageSelector,
  chatSelectedAdvisorIds,
  chatIsPickUp,
  chatIsMobileTechnician,
  chatSelectedJobDateTimeSelector,
  chatJobTypeSelector,
  chatJobNotesSelector,
  chatEmployeeSelector,
  chatPreferredAddressDataSelector,
  chatRemoteZoneSelector,
  chatRemoteGeolocationSelector,
} from 'selectors/booking/chat-selectors';
import {
  appDealershipIdSelector,
  appBookingIdSelector,
} from 'selectors/booking/app-selectors';

import {
  FETCH_CUSTOMER_SUCCESS,
  SET_CURRENT_CUSTOMER,
  SET_AVAILABLE_RECALLS,
  FETCH_VEHICLES_SUCCESS,
  SET_VEHICLE_ID,
  SET_JOB_REACHABLE,
  SET_CURRENT_VEHICLE,
  SET_SKIP_STEPS_TO_MILAGE,
  SET_SKIP_STEPS_TO_CAR_SELECTION,
  SET_SKIP_STEPS_TO_CONFIRM_IDENTITY_STEP,
  setLoadingState,
  initializeStep,
  initializeCustomerIdentificationStep,
  initializeConfirmIdentifyStep,
  initializeCarCreationStep,
  initializeCarSelectionStep,
  initializeErrorStep,
  initializeWelcomeStep,
  initializeServiceSelectionStep,
  initializeDateSelectionStep,
  initializeJobCreationStep,
  updateCurrentStep,
  fetchJobCreationSlots,
} from './chat-actions';

export const EXIT = 'EXIT';
export const SET_EMBED_WIDGET_OPEN = 'SET_EMBED_WIDGET_OPEN';

const FETCH_CUSTOMERS_RESPONSE_TYPE = 'import_customers';
const ADD_CUSTOMER_RESPONSE_TYPE = 'add_customer';
export const FETCH_VEHICLES_RESPONSE_TYPE = 'import_vehicles';
const ADD_VEHICLE_RESPONSE_TYPE = 'add_vehicle';
const UPDATE_VEHICLE_RESPONSE_TYPE = 'update_vehicle';
const ADD_APPOINTMENT_RESPONSE_TYPE = 'add_appointment';
const IMPORT_RECALLS_RESPONSE_TYPE = 'import_recalls';
const IS_JOB_REACHABLE = 'is_job_reachable';
const JOB_CREATED = 'add_job';

export const exit = () => (dispatch) => {
  dispatch({ type: EXIT, payload: {} });
  dispatch(initializeWelcomeStep({ isNewCustomer: false }));
};

export const setWidgetClose = () => (dispatch) => {
  dispatch({ type: SET_EMBED_WIDGET_OPEN, payload: false });
};

export const actionCableListener = message => (dispatch, getState) => {
  if (isDevelopmentEnv) {
    console.log('Action cable response: ', message); // eslint-disable-line no-console
  }
  const {
    action, data, errors, full_errors: fullErrors,
  } = message;
  const errorMessage = errors && (errors.error || errors);
  dispatch(setLoadingState(false));

  switch (action) {
    case FETCH_CUSTOMERS_RESPONSE_TYPE:
      if (data) {
        let items = {};

        data.forEach((item) => {
          const vehicles = [];

          item.vehicles.forEach((vehicle) => {
            vehicles.push({
              ...vehicle,
              customerId: item.id,
            });
          });

          const name = (item.first_name || item.last_name).toLowerCase();

          if (name in items) {
            items[name] = {
              ...items[name],
              vehicles: [...items[name].vehicles, ...vehicles],
            };
          } else {
            items[name] = {
              ...item,
              vehicles,
            };
          }
        });

        items = Object.values(items);

        const skipSteps = chatSelectedSkipStepsSelector(getState());
        const vehicleIdFromParams = chatSelectedVehicleIdFromParamsSelector(getState());
        let currentCustomer = null;
        let currentVehicle = null;

        items.forEach((item) => {
          if (!currentCustomer) {
            item.vehicles.forEach((vehicle) => {
              if (vehicle.vin === vehicleIdFromParams) {
                currentVehicle = {
                  id: vehicle.id,
                  customerId: vehicle.customerId,
                  name: `${vehicle.vehicle_set.make} ${vehicle.vehicle_set.model}`,
                  make: vehicle.vehicle_set.make,
                  model: vehicle.vehicle_set.model,
                  year: vehicle.vehicle_set.model_year,
                  mileage: vehicle.mileage || 0,
                  vin: vehicle.vin,
                  image: vehicle.image,
                };

                currentCustomer = item;
              }
            });
          }
        });

        if (skipSteps) {
          if (currentCustomer) {
            dispatch({
              type: FETCH_CUSTOMER_SUCCESS,
              payload: {
                allCustomers: data,
                otherCustomers: [],
                currentCustomer,
              },
            });

            dispatch({ type: SET_SKIP_STEPS_TO_MILAGE, payload: true });

            dispatch(actionCableListener({
              action: FETCH_VEHICLES_RESPONSE_TYPE,
              data: items[0].vehicles,
            }));

            dispatch({
              type: SET_VEHICLE_ID,
              payload: { id: currentVehicle.id },
            });
            dispatch({
              type: SET_CURRENT_CUSTOMER,
              payload: {
                ...currentCustomer,
                vehicles: currentVehicle.vehicles,
              },
            });
            dispatch({ type: SET_CURRENT_VEHICLE, payload: currentVehicle });
            dispatch(initializeStep(MILEAGE_STEP));
          } else if (items.length > 1) {
            dispatch({
              type: FETCH_CUSTOMER_SUCCESS,
              payload: {
                allCustomers: data,
                otherCustomers: items.slice(1),
                currentCustomer: items[0],
              },
            });
            dispatch({
              type: SET_SKIP_STEPS_TO_CONFIRM_IDENTITY_STEP,
              payload: true,
            });
            dispatch(initializeConfirmIdentifyStep(chatCurrentCustomerSelector(getState())));
          } else if (items.length === 1) {
            dispatch({
              type: FETCH_CUSTOMER_SUCCESS,
              payload: {
                allCustomers: data,
                otherCustomers: [],
                currentCustomer: items[0],
              },
            });
            dispatch({ type: SET_SKIP_STEPS_TO_CAR_SELECTION, payload: true });
            dispatch(actionCableListener({
              action: FETCH_VEHICLES_RESPONSE_TYPE,
              data: items[0].vehicles,
            }));
          } else {
            dispatch({
              type: FETCH_CUSTOMER_SUCCESS,
              payload: {
                allCustomers: data,
                otherCustomers: items.slice(1),
                currentCustomer: items[0],
              },
            });
            dispatch(initializeConfirmIdentifyStep(chatCurrentCustomerSelector(getState())));
          }
        } else {
          dispatch({
            type: FETCH_CUSTOMER_SUCCESS,
            payload: {
              allCustomers: data,
              otherCustomers: items.slice(1),
              currentCustomer: items[0],
            },
          });
          dispatch(initializeConfirmIdentifyStep(chatCurrentCustomerSelector(getState())));
        }
      } else if (
        fullErrors.find(({ code }) =>
          code === DT_CUSTOMER_NOT_FOUND_ERROR_CODE ||
            code === CDK_EXTRACT_CUSTOMER_NOT_FOUND_ERROR_CODE ||
            code.slice(0, 3) === '600')
      ) {
        const previousIdentificationMethod = chatIdentificationMethodSelector(getState());
        const previousPhoneNumber = chatPreferredPhoneNumberSelector(getState());
        // eslint-disable-next-line max-len
        dispatch(initializeCustomerIdentificationStep(
          previousIdentificationMethod,
          previousPhoneNumber,
        ));
      } else {
        dispatch(initializeErrorStep(errorMessage, true));
      }
      break;
    case ADD_CUSTOMER_RESPONSE_TYPE:
      if (data) {
        dispatch({ type: SET_CURRENT_CUSTOMER, payload: data });
        dispatch(initializeCarCreationStep());
      } else {
        dispatch(initializeErrorStep(errorMessage, true));
      }
      break;
    case FETCH_VEHICLES_RESPONSE_TYPE:
      if (data) {
        dispatch({ type: FETCH_VEHICLES_SUCCESS, payload: { vehicles: data } });
        if (!chatSelectedSkipStepsToMilageSelector(getState())) {
          dispatch(initializeCarSelectionStep());
        }
      } else if (errors) {
        dispatch(initializeErrorStep(errors, true));
      } else {
        dispatch(initializeCarCreationStep());
      }
      break;
    case ADD_VEHICLE_RESPONSE_TYPE:
      if (data) {
        dispatch({ type: SET_VEHICLE_ID, payload: { id: data.id } });
        dispatch(setLoadingState(false));
        dispatch(initializeServiceSelectionStep());
      } else {
        dispatch(initializeErrorStep(errorMessage, true));
      }
      break;
    case UPDATE_VEHICLE_RESPONSE_TYPE:
      dispatch(setLoadingState(false));
      dispatch(initializeServiceSelectionStep());
      break;
    case IMPORT_RECALLS_RESPONSE_TYPE: {
      dispatch(setLoadingState(false));

      if (data.length) {
        dispatch(initializeStep(AVAILABLE_RECALLS_STEP));
        dispatch(setLoadingState(true));
        setTimeout(() => {
          dispatch({
            type: SET_AVAILABLE_RECALLS,
            payload: {
              recalls: data.map(recall => ({
                ...recall,
                operationCode: recall.operation_code,
                actionType: recall.action_type,
                fee: 0,
                package_items: [],
              })),
            },
          });
          dispatch(setLoadingState(false));
        }, DELAY_1500);
      } else {
        dispatch(initializeJobCreationStep());
      }
      break;
    }
    case JOB_CREATED:
      if (data) {
        dispatch(setLoadingState(false));
        dispatch(updateCurrentStep({ showMessage: true }));
      } else {
        dispatch(initializeErrorStep(errorMessage, false));
      }
      break;
    case ADD_APPOINTMENT_RESPONSE_TYPE:
      if (data) {
        const addressData = {
          ...chatPreferredAddressDataSelector(getState()),
        };
        delete addressData.address;

        if (chatIsPickUp(getState())) {
          addPickupJob(
            appBookingIdSelector(getState()),
            appDealershipIdSelector(getState()),
            addressData,
            chatSelectedJobDateTimeSelector(getState()),
            chatJobTypeSelector(getState()),
            chatJobNotesSelector(getState()),
            data.id,
            chatRemoteGeolocationSelector(getState()),
            chatRemoteZoneSelector(getState()),
            chatEmployeeSelector(getState()).id,
          ).catch(error => dispatch(initializeErrorStep(error, true)));
        } else if (chatIsMobileTechnician(getState())) {
          addTechnicianJob(
            appBookingIdSelector(getState()),
            appDealershipIdSelector(getState()),
            addressData,
            chatSelectedJobDateTimeSelector(getState()),
            chatJobTypeSelector(getState()),
            chatJobNotesSelector(getState()),
            data.id,
            chatRemoteGeolocationSelector(getState()),
            chatEmployeeSelector(getState()).id,
            chatRemoteZoneSelector(getState()),
          ).catch(error => dispatch(initializeErrorStep(error, true)));
        } else {
          dispatch(updateCurrentStep({ showMessage: true }));
        }
      } else if (
        errorMessage.code === DEALERSHIP_CLOSED_ERROR_CODE ||
        errorMessage === APPOINTMENT_SLOT_FULL_ERROR_MESSAGE
      ) {
        dispatch(initializeDateSelectionStep({
          advisorIds: chatSelectedAdvisorIds(getState()),
          allowReselection: true,
        }));
      } else {
        dispatch(initializeErrorStep(errorMessage, false));
      }
      break;
    case IS_JOB_REACHABLE: {
      const preferredAddress = chatPreferredAddressSelector(getState());

      if (data && data.reachable) {
        dispatch({ type: SET_JOB_REACHABLE, payload: data.reachable });
        dispatch(updateCurrentStep({ isComplete: true, preferredAddress }));
        dispatch(fetchJobCreationSlots());
      } else {
        const currentErrors = errors || data.errors;

        dispatch(updateCurrentStep(
          { isComplete: true, preferredAddress, errors: currentErrors },
          { errors: currentErrors },
        ));
      }
      break;
    }
    default:
      break;
  }
};
