import { toFixedPrecision } from 'shared/utils';

import {
  CHANGE_STEP,
  UNDO_STEP,
  UPDATE_CURRENT_STEP,
  FETCH_CUSTOMER_SUCCESS,
  SET_CURRENT_CUSTOMER,
  FETCH_VEHICLES_SUCCESS,
  FETCH_SERVICES_SUCCESS,
  FETCH_ADVISORS_SUCCESS,
  FETCH_TEAM_TAG_SUCCESS,
  FETCH_AVAILABLE_TRANSPORTS_SUCCESS,
  FETCH_AVAILABLE_PICKUP_JOBS_SUCCESS,
  DENY_PICKUP_JOBS_CREATION,
  FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS,
  SET_CHAT_LOADING_STATE,
  SET_CURRENT_VEHICLE,
  UPDATE_MILEAGE,
  SELECT_SERVICES,
  SET_AVAILABLE_RECALLS,
  TOGGLE_RECALL,
  CLEAR_SELECTED_RECALLS,
  SELECT_ADVISOR,
  SELECT_ADVISOR_DATE,
  SET_ADVISOR_IDS,
  SELECT_DATE,
  SELECT_JOB_DATE,
  SET_ID_METHOD,
  SET_CLIENT_WAITING,
  SET_SEND_SMS,
  SET_PHONE_NUMBER,
  SET_EMAIL,
  SET_PREFERRED_ADDRESS,
  SET_VEHICLE_ID,
  SET_VEHICLE_ID_FROM_PARAMS,
  SET_LAST_REQUEST,
  MARK_DONE_ANIMATIONS,
  FETCH_APPOINTMENT_SLOTS_SUCCESS,
  FETCH_JOB_SLOTS_SUCCESS,
  SET_APPRAISAL_REQUESTED,
  SELECT_TRANSPORT,
  SET_SKIP_STEPS,
  SET_SKIP_STEPS_TO_MILAGE,
  SET_SKIP_STEPS_TO_CAR_SELECTION,
  SET_SKIP_STEPS_TO_CONFIRM_IDENTITY_STEP,
  SET_BOOKING_SOURCE,
  SET_IS_PICK_UP,
  SET_IS_DROP_OFF,
  SET_IS_MOBILE_TECHNICIAN,
  FETCH_DRIVERS_SUCCESS,
  FETCH_TECHNICIANS_SUCCESS,
  SET_REMOTE_ZONE,
  SET_REMOTE_GEOLOCATION,
  SET_SKIP_PICK_UP,
  SET_SKIP_MOBILE_TECHNICIAN,
  SET_JOB_TYPE,
  SET_PICK_UP_AVAILABLE,
  SET_MOBILE_TECHNICIAN_AVAILABLE,
  SET_TIMECODES,
  SET_SKIP_LOGIN,
} from 'actions/booking/chat-actions';
import { EXIT } from 'actions/booking/app-actions';
import { FIRST_AVAILABLE_ADVISOR_ID } from 'shared/constants';

import STEPS, { MAX_PROGRESS } from 'components/steps/booking';

const initialState = {
  lastId: 0,
  loadingState: false,
  history: [],
  stepHistory: [],
  allCustomers: [],
  otherCustomers: [],
  currentCustomer: null,
  currentVehicle: null,
  currentInput: null,
  makeModelYearMap: {},
  availableServices: {},
  availableAdvisors: [],
  jobSlots: [],
  appointmentSlots: [],
  availableTransports: [],
  availablePickupJobs: null,
  selectedServices: {},
  selectedAdvisor: null,
  selectedAdvisorIds: [],
  phantomAdvisorIds: [],
  selectedDateTime: null,
  selectedJobDateTime: null,
  selectedTransport: null,
  jobType: 'pickup',
  jobNotes: '',
  clientWaiting: false,
  sendSms: true,
  progress: 0,
  preferredAddress: null,
  preferredPhoneNumber: null,
  appraisalRequested: false,
  lastRequest: null,
  waiterAllowed: false,
  selectedRecalls: [],
  skipSteps: false,
  skipStepsToMilage: false,
  skipStepsToCarSelection: false,
  skipStepToConfirmIdentity: false,
  vehicleIdFromParams: null,
  bookingSource: 'customer',
  isPickUp: false,
  skipPickUp: false,
  isDropOff: false,
  isMobileTechnician: false,
  skipMobileTechnician: false,
  drivers: [],
  technicians: [],
  employee: null,
  remoteZone: null,
  geolocation: {},
  pickUpAvailable: false,
  mobileTechnicianAvailable: false,
  loginSkipped: false,

};

const toggleService = (collection, service) => (
  collection.find(({ id }) => service.id === id)
    ? collection.filter(({ id }) => id !== service.id)
    : collection.concat(service)
);

const chat = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case EXIT:
      return initialState;
    case CHANGE_STEP:
      return {
        ...state,
        stepHistory: [
          ...state.stepHistory,
          payload.step,
        ],
        history: state.history.concat({
          component: STEPS[payload.step].step,
          props: payload.props || {},
          id: state.lastId + 1,
        }),
        currentInput: STEPS[payload.step].input && {
          component: STEPS[payload.step].input,
          props: { stepId: state.lastId + 1, ...payload.inputProps },
        },
        progress: toFixedPrecision((STEPS[payload.step].progress * 100) / MAX_PROGRESS, 2),
        lastId: state.lastId + 1,
      };
    case SET_SKIP_STEPS:
      return {
        ...state,
        skipSteps: payload,
      };
    case SET_SKIP_STEPS_TO_MILAGE:
      return {
        ...state,
        skipStepsToMilage: payload,
      };
    case SET_SKIP_STEPS_TO_CAR_SELECTION:
      return {
        ...state,
        skipStepsToCarSelection: payload,
      };
    case SET_SKIP_STEPS_TO_CONFIRM_IDENTITY_STEP:
      return {
        ...state,
        skipStepToConfirmIdentity: payload,
      };
    case SET_SKIP_LOGIN:
      return {
        ...state,
        loginSkipped: payload,
      };
    case UNDO_STEP: {
      const prevStep = state.stepHistory[state.stepHistory.length - 2];

      return {
        ...state,
        loginSkipped: false,
        stepHistory: state.stepHistory.slice(0, -2).concat(prevStep),
        history: state.history.slice(0, -2).concat({
          component: STEPS[prevStep].step,
          props: {},
          id: state.lastId - 1,
        }),
        currentInput: STEPS[prevStep].input && {
          component: STEPS[prevStep].input,
          props: { stepId: state.lastId - 1 },
        },
        prevStep: STEPS[prevStep].prev,
        progress: toFixedPrecision((STEPS[prevStep].progress * 100) / MAX_PROGRESS, 2),
        lastId: state.lastId - 1,
      };
    }
    case UPDATE_CURRENT_STEP:
      return {
        ...state,
        history: state.history.slice(0, -1).concat({
          ...state.history[state.history.length - 1],
          props: {
            ...state.history[state.history.length - 1].props,
            ...payload.props,
          },
        }),
        currentInput: state.currentInput && {
          ...state.currentInput,
          props: {
            ...state.currentInput.props,
            ...payload.inputProps,
          },
        },
      };
    case SET_CHAT_LOADING_STATE:
      return {
        ...state,
        loadingState: payload.value,
        loadingStateDelay: payload.delay,
      };
    case FETCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        loadingState: false,
        allCustomers: payload.allCustomers,
        otherCustomers: payload.otherCustomers,
        currentCustomer: {
          id: payload.currentCustomer.id,
          name: payload.currentCustomer.first_name,
          lastName: payload.currentCustomer.last_name,
          phoneNumber: payload.currentCustomer.phone_number,
          customerNumber: payload.currentCustomer.customer_number,
          address: payload.currentCustomer.address,
          addressLine1: payload.currentCustomer.address_line1,
          addressLine2: payload.currentCustomer.address_line2,
          addressCity: payload.currentCustomer.address_city,
          addressState: payload.currentCustomer.address_state,
          addressZipcode: payload.currentCustomer.address_zipcode,
          lastAddress: payload.currentCustomer.last_address,
          vehicles: payload.currentCustomer.vehicles,
          customerType: payload.currentCustomer.customer_type,
        },
      };
    case SET_ID_METHOD:
      return {
        ...state,
        identificationMethod: payload.method,
      };
    case SET_CURRENT_CUSTOMER:
      return {
        ...state,
        currentCustomer: {
          id: payload.id,
          name: payload.first_name,
          lastName: payload.last_name,
          phoneNumber: payload.phone_number,
          customerNumber: payload.customer_number,
          address: payload.address,
          addressLine1: payload.address_line1,
          addressLine2: payload.address_line2,
          addressCity: payload.address_city,
          addressState: payload.address_state,
          addressZipcode: payload.address_zipcode,
          lastAddress: payload.last_address,
          vehicles: payload.vehicles,
          customerType: payload.customer_type,
        },
      };
    case FETCH_VEHICLES_SUCCESS:
      return {
        ...state,
        loadingState: false,
        vehicles: ([].concat(...[], payload.vehicles)).map(vehicle => ({
          id: vehicle.id,
          customerId: vehicle.customerId,
          name: `${vehicle.vehicle_set.make} ${vehicle.vehicle_set.model}`,
          make: vehicle.vehicle_set.make,
          model: vehicle.vehicle_set.model,
          year: vehicle.vehicle_set.model_year,
          mileage: vehicle.mileage || 0,
          vin: vehicle.vin,
          image: vehicle.image,
        })),
      };
    case FETCH_MAKE_MODEL_YEAR_MAP_SUCCESS:
      return {
        ...state,
        loadingState: false,
        makeModelYearMap: payload,
      };
    case FETCH_AVAILABLE_TRANSPORTS_SUCCESS:
      return {
        ...state,
        loadingState: false,
        availableTransports: payload.transports,
      };
    case FETCH_AVAILABLE_PICKUP_JOBS_SUCCESS:
      return {
        ...state,
        loadingState: false,
        availablePickupJobs: payload,
      };
    case DENY_PICKUP_JOBS_CREATION:
      return {
        ...state,
        availablePickupJobs: false,
      };
    case SET_CURRENT_VEHICLE:
      return {
        ...state,
        currentVehicle: payload,
      };
    case UPDATE_MILEAGE:
      return {
        ...state,
        currentVehicle: {
          ...state.currentVehicle,
          mileage: payload.mileage,
        },
      };
    case SET_VEHICLE_ID:
      return {
        ...state,
        currentVehicle: {
          ...state.currentVehicle,
          id: payload.id,
        },
      };
    case SET_VEHICLE_ID_FROM_PARAMS:
      return {
        ...state,
        vehicleIdFromParams: payload,
      };
    case FETCH_SERVICES_SUCCESS:
      return {
        ...state,
        availableServices: {
          maintenance: payload.maintenance || [],
          concern: payload.concern || [],
        },
      };
    case SELECT_SERVICES:
      return {
        ...state,
        selectedServices: payload.services,
      };
    case SET_AVAILABLE_RECALLS:
      return {
        ...state,
        availableRecalls: payload.recalls,
        selectedRecalls: [],
      };
    case TOGGLE_RECALL:
      return {
        ...state,
        selectedRecalls: toggleService(state.selectedRecalls, payload.service),
      };
    case CLEAR_SELECTED_RECALLS:
      return {
        ...state,
        selectedRecalls: [],
      };
    case FETCH_ADVISORS_SUCCESS:
      return {
        ...state,
        availableAdvisors: payload.advisors,
        selectedAdvisor: payload.advisors.find(advisor => advisor.id !== 'firstAvailable') ? payload.advisor : null,
      };
    case FETCH_TEAM_TAG_SUCCESS:
      return {
        ...state,
        responsibleTeamTag: payload,
      };
    case SELECT_ADVISOR:
      return {
        ...state,
        selectedAdvisor: payload.advisor,
        firstAvailable: payload.advisor.id === FIRST_AVAILABLE_ADVISOR_ID,
      };
    case SELECT_ADVISOR_DATE:
      return {
        ...state,
        selectedAdvisor: payload.advisor,
        employee: payload.employee,
      };
    case SET_ADVISOR_IDS:
      return {
        ...state,
        selectedAdvisorIds: payload.advisorIds,
        phantomAdvisorIds: payload.phantomAdvisorIds,
      };
    case SELECT_DATE:
      return {
        ...state,
        selectedDateTime: payload.dateTime,
      };
    case SELECT_JOB_DATE:
      return {
        ...state,
        selectedJobDateTime: payload.dateTime,
      };
    case SET_CLIENT_WAITING:
      return {
        ...state,
        clientWaiting: payload.isWaiting,
      };
    case SELECT_TRANSPORT:
      return {
        ...state,
        selectedTransport: payload.selectedTransport,
      };
    case SET_SEND_SMS:
      return {
        ...state,
        sendSms: payload.sendSms,
      };
    case SET_PREFERRED_ADDRESS:
      return {
        ...state,
        preferredAddress: payload.preferredAddress,
        preferredAddressData: payload.preferredAddressData,
      };
    case SET_PHONE_NUMBER:
      return {
        ...state,
        preferredPhoneNumber: payload.preferredPhoneNumber,
      };
    case SET_EMAIL:
      return {
        ...state,
        email: payload.email,
      };
    case SET_LAST_REQUEST:
      return {
        ...state,
        lastRequest: payload.request,
      };
    case MARK_DONE_ANIMATIONS:
      return {
        ...state,
        history: state.history.map(step => ({
          ...step,
          props: {
            ...step.props,
            isAnimationFinished: true,
          },
        })),
        currentInput: state.currentInput && {
          ...state.currentInput,
          props: {
            ...state.currentInput.props,
            isAnimationFinished: true,
          },
        },
      };
    case FETCH_APPOINTMENT_SLOTS_SUCCESS:
      return {
        ...state,
        appointmentSlots: payload,
      };

    case FETCH_JOB_SLOTS_SUCCESS:
      return {
        ...state,
        jobSlots: payload,
      };
    case SET_APPRAISAL_REQUESTED:
      return {
        ...state,
        appraisalRequested: payload.appraisalRequested,
      };
    case SET_BOOKING_SOURCE:
      return {
        ...state,
        bookingSource: payload.bookingSource,
      };
    case SET_IS_PICK_UP:
      return {
        ...state,
        isPickUp: payload,
      };
    case SET_SKIP_PICK_UP:
      return {
        ...state,
        skipPickUp: payload,
      };
    case SET_IS_DROP_OFF:
      return {
        ...state,
        isDropOff: payload,
      };
    case SET_IS_MOBILE_TECHNICIAN:
      return {
        ...state,
        isMobileTechnician: payload,
      };
    case SET_SKIP_MOBILE_TECHNICIAN:
      return {
        ...state,
        skipMobileTechnician: payload,
      };
    case SET_PICK_UP_AVAILABLE:
      return {
        ...state,
        pickUpAvailable: payload,
      };
    case SET_MOBILE_TECHNICIAN_AVAILABLE:
      return {
        ...state,
        mobileTechnicianAvailable: payload,
      };
    case FETCH_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: payload,
      };
    case FETCH_TECHNICIANS_SUCCESS:
      return {
        ...state,
        technicians: payload,
      };
    case SET_REMOTE_ZONE:
      return {
        ...state,
        remoteZone: payload,
      };
    case SET_REMOTE_GEOLOCATION:
      return {
        ...state,
        geolocation: payload,
      };
    case SET_JOB_TYPE:
      return {
        ...state,
        jobType: payload,
      };
    case SET_TIMECODES:
      return {
        ...state,
        timeCodes: payload,
      };
    default:
      return state;
  }
};

export default chat;
