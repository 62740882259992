import React, { Fragment, useState } from 'react';
import { func, string } from 'prop-types';
import cx from 'classnames';

import { extractPhoneNumberFromString, toStructuredForSelect } from 'shared/utils';
import { BUSINESS_CUSTOMER_TYPE, INDIVIDUAL_CUSTOMER_TYPE } from 'shared/constants';
import {
  phoneNumberFormatValidator,
  phoneNumberLengthValidator,
  emailValidator,
  fullNameValidator,
} from 'shared/validators';

import Button from 'components/common/Button';
import StyledSelect from 'components/common/StyledSelect';

import styles from './styles.module.scss';
import Disclaimer from './Disclaimer';

const CustomerCreationStepInput = ({
  phoneNumber, onSubmit, dmsType,
}) => {
  const dealershipIdElement = document.getElementById('concierge-dealership-id');
  const make = (dealershipIdElement?.attributes?.make?.value || '').toLowerCase();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [customerType, setCustomerType] = useState('individual');
  const [companyName, setCompanyName] = useState('');
  const [phone, setPhone] = useState(phoneNumber);
  const [errors, setErrors] = useState({
    name: null,
    email: null,
    phone: null,
    customerType: null,
    companyName: null,
  });
  const [isComplete, setIsComplete] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(true);

  const validate = () => {
    const phoneNumberFormatValid = phoneNumberFormatValidator(phone);
    const phoneNumberLengthValid = phoneNumberFormatValid && phoneNumberLengthValidator(
      extractPhoneNumberFromString(phone),
      dmsType,
    );
    const error = {
      name: (customerType === 'individual' && !fullNameValidator(name)) && 'A full name must be present',
      company_name: (customerType === 'business' && !companyName.length) && 'A company name must be present',
      phone: (!phoneNumberFormatValid || !phoneNumberLengthValid) && 'A valid phone number is required.',
      email: !emailValidator(email) && 'A valid email address is required',
    };
    setErrors(error);

    return !error.name && !error.email && !error.phone && !error.companyName;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (validate()) {
      const formattedPhoneNumber = extractPhoneNumberFromString(phone);

      onSubmit({
        name,
        customer_type: customerType,
        company_name: companyName,
        email,
        phone: formattedPhoneNumber,
      });

      setIsComplete(true);
    }
  };

  const handleNameChange = event => setName(event.target.value);
  const handleCompanyNameChange = event => setCompanyName(event.target.value);
  const handleEmailChange = event => setEmail(event.target.value);
  const handlePhoneChange = event => setPhone(event.target.value);
  const handleCustomerTypeChange = (type) => {
    setCustomerType(type.value);
    setName('');
    setCompanyName('');
  };


  const inputFields = [
    {
      customerType: INDIVIDUAL_CUSTOMER_TYPE,
      label: 'Full name',
      value: name,
      onChange: handleNameChange,
      error: errors.name,
    },
    {
      customerType: BUSINESS_CUSTOMER_TYPE,
      label: 'Company name',
      value: companyName,
      onChange: handleCompanyNameChange,
      error: errors.companyName,
    },
    {
      customerType: 'all',
      label: 'Email address',
      value: email,
      onChange: handleEmailChange,
      error: errors.email,
    },
    {
      customerType: 'all',
      label: 'Phone number',
      value: phone,
      onChange: handlePhoneChange,
      error: errors.phone,
    },
  ];

  const selectFields = [
    {
      label: 'Customer',
      currentValue: customerType,
      onChange: handleCustomerTypeChange,
      possibleValues: [BUSINESS_CUSTOMER_TYPE, INDIVIDUAL_CUSTOMER_TYPE],
      error: errors.customerType,
    },
  ];

  const renderInput = field => (
    <Fragment key={field.label}>
      <div className={styles.labelContainer}>
        <span className={styles.label}>{field.label}</span>
        <span className={styles.required}>*</span>
      </div>
      <input
        className={cx(styles.input, { [styles.invalid]: !!field.error })}
        type="text"
        value={field.value || ''}
        onChange={field.onChange}
      />
      <span className={styles.error}>{field.error}</span>
    </Fragment>
  );

  const renderSelect = field => (
    <Fragment key={field.label}>
      <div className={styles.labelContainer}>
        <span className={styles.label}>{field.label}</span>
        <span className={styles.required}>*</span>
      </div>
      <StyledSelect
        isSearchable
        menuPlacement="bottom"
        value={{
          label: field.currentValue,
          value: field.currentValue,
        }}
        options={toStructuredForSelect(field.possibleValues)}
        className={cx(styles.select, { [styles.invalid]: !!field.error })}
        onChange={value => field.onChange(value)}
        error={field.error}
      />
    </Fragment>
  );

  if (isComplete) {
    return null;
  }

  return (
    <form className={styles.container}>
      {dmsType === 'cdk' && selectFields.map(field => renderSelect(field))}
      {inputFields.map(field => (field.customerType === customerType || field.customerType === 'all')
        && renderInput(field))
      }
      <Disclaimer isConfirmed={isConfirmed} setIsConfirmed={setIsConfirmed} make={make} />
      <Button
        disabled={!isConfirmed}
        className={styles.submit}
        caption="Submit"
        onClick={handleSubmit}
        isWide
      />
    </form>
  );
};

CustomerCreationStepInput.propTypes = {
  phoneNumber: string,
  onSubmit: func.isRequired,
  dmsType: string.isRequired,
};

CustomerCreationStepInput.defaultProps = {
  phoneNumber: '',
};

export default CustomerCreationStepInput;
