import React, { useState } from 'react';
import cx from 'classnames';
import { func, string, bool } from 'prop-types';
import { connect } from 'react-redux';

import { extractPhoneNumberFromString } from 'shared/utils';
import {
  phoneNumberFormatValidator,
  phoneNumberLengthValidator,
} from 'shared/validators';
import { submitCustomerIdentification } from 'actions/booking/chat-actions';
import { appDealershipDmsTypeSelector } from 'selectors/booking/app-selectors';
import { chatPreferredPhoneNumberSelector, chatLoginSkippedSelector } from 'selectors/booking/chat-selectors';

import Button from 'components/common/Button';
import sendIcon from 'assets/images/send.svg';

import styles from './styles.module.scss';

const WelcomeStepInput = ({
  onSubmit,
  dmsType,
  isStandAlone,
  phone,
  loginSkipped,
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [valid, setValid] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const handleChange = event => setPhoneNumber(event.target.value);

  const handleSubmit = (event) => {
    event.preventDefault();

    const numberString = phoneNumber;
    let extractedPhoneNumber = null;

    if (phoneNumberFormatValidator(numberString)) {
      extractedPhoneNumber = extractPhoneNumberFromString(numberString);
    }

    if (
      extractedPhoneNumber &&
      phoneNumberLengthValidator(extractedPhoneNumber, dmsType)
    ) {
      setValid(true);
      setDisabled(true);
      onSubmit({ phoneNumber: extractedPhoneNumber });
    } else {
      setValid(false);
    }
  };

  if (disabled || phone || loginSkipped) {
    return null;
  }

  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <div className={styles.inputContainer}>
        <input
          autoFocus
          className={cx(styles.input, {
            [styles.invalid]: !valid,
            [styles.isStandAlone]: isStandAlone,
          })}
          value={phoneNumber}
          onChange={handleChange}
        />
        {!isStandAlone && (
          <button className={styles.submit} onClick={handleSubmit}>
            <img alt="send icon" className={styles.icon} src={sendIcon} />
          </button>
        )}
      </div>
      {!valid && (
        <span className={styles.error}>
          Please provide a valid phone number.
        </span>
      )}
      {isStandAlone && (
        <Button
          className={styles.inputButton}
          caption="Confirm"
          onClick={handleSubmit}
          isWide
        />
      )}
    </form>
  );
};

WelcomeStepInput.propTypes = {
  onSubmit: func.isRequired,
  dmsType: string,
  isStandAlone: bool,
  phone: string,
  loginSkipped: bool,
};

WelcomeStepInput.defaultProps = {
  isStandAlone: false,
  phone: null,
  dmsType: null,
  loginSkipped: false,
};

const mapStateToProps = state => ({
  dmsType: appDealershipDmsTypeSelector(state),
  phone: chatPreferredPhoneNumberSelector(state),
  loginSkipped: chatLoginSkippedSelector(state),
});

const actions = {
  onSubmit: submitCustomerIdentification,
};

const WelcomeStepInputContainer = connect(
  mapStateToProps,
  actions,
)(WelcomeStepInput);

export default WelcomeStepInputContainer;
