import React, { Fragment } from 'react';
import { string, bool } from 'prop-types';

import { DELAY_500 } from 'shared/constants/delays';
import { SOURCE_CONCIERGE, SOURCE_USER } from 'components/common/TextMessage';
import AnimationGroup from 'components/common/AnimationGroup';
import AnimatedTextMessage from 'components/common/AnimatedTextMessage';

const WelcomeStep = ({
  flatMode, phoneNumber, isComplete, isAnimationFinished,
}) => (
  <Fragment>
    {!flatMode &&
    <AnimationGroup isComplete={isAnimationFinished}>
      <AnimatedTextMessage
        message="Hello, there!"
        source={SOURCE_CONCIERGE}
        isOpening
      />
      <AnimatedTextMessage
        delay={DELAY_500}
        message="Please enter your cell phone number to continue"
        source={SOURCE_CONCIERGE}
        isClosing
        timecodes={{ from: 2000, to: 12000 }}
      />
    </AnimationGroup>
    }
    {isComplete && (
      <AnimationGroup isComplete={isAnimationFinished}>
        <AnimatedTextMessage
          message={phoneNumber ? `It's ${phoneNumber}.` : "I'm a new customer"}
          source={SOURCE_USER}
        />
      </AnimationGroup>
    )}
  </Fragment>
);

WelcomeStep.propTypes = {
  phoneNumber: string,
  isComplete: bool,
  isAnimationFinished: bool,
  flatMode: bool,
};

WelcomeStep.defaultProps = {
  phoneNumber: '',
  isComplete: false,
  isAnimationFinished: false,
  flatMode: false,

};

export default WelcomeStep;
